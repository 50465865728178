
import React, { useState, useEffect }  from "react";
import { connect } from "react-redux";
import moment from 'moment';

import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  updateimage,
} from '../api/image.api';
import { getAllitem } from '../api/item.api';



const LoftyimageUpdateModal = ({open, handleClose, successAction, token, doc}) => {
  const [dataLoftyitem, setDataLoftyitem] = useState([]);

  
  useEffect(() => {
    const handleDataLoftyApps = async () => {
      try {
				const resLoftyitem = await getAllitem({ token });
				setDataLoftyitem(resLoftyitem.data);


      } catch (error) {
        // error
      }
    }
    handleDataLoftyApps();
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        Actualizar image
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async e => {
            e.preventDefault();
            try {
              await updateimage({
                token: token,
                _id: doc._id,
                idItem: e.target.idItem.value,

              });
              if (successAction) {
                successAction();
              }
              alert('Se agrego exitosamente el dato');
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Lo sentimos no se logro agregar el dato');
              }
            }
          }}
        >
  				<div className="my-3">
					<TextField name="idItem" label="idItem" defaultValue={doc?.idItem} variant="outlined" fullWidth select>
						{
							dataLoftyitem.map(item => (
								<MenuItem key={item._id} value={item._id}>{item.description}</MenuItem>
							))
						}
					</TextField>
				</div>

          <div className="my-3 text-center">
            <Button type="submit" variant="outlined" color="primary">
              Actualizar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(LoftyimageUpdateModal);
