
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  createlogcall,
} from '../api/logcall.api';
import { getAllitem } from '../api/item.api';

import { getUsers } from '../api/user.api';

const LoftylogcallAddModal = ({open, handleClose, successAction, token}) => {
  const [dataLoftyitem, setDataLoftyitem] = useState([]);

  const [dataUser, setDataUser] = useState([]);

  useEffect(() => {
    const handleDataLoftyApps = async () => {
      try {
				const resLoftyitem = await getAllitem({ token });
				setDataLoftyitem(resLoftyitem.data);

				const resUser = await getUsers({ token });
				setDataUser(resUser.data);

      } catch (error) {
        // error
      }
    }
    handleDataLoftyApps();
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        Agregar logcall
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async e => {
            e.preventDefault();
            try {
              await createlogcall({
                token: token,
                userId: e.target.userId.value,
							idItem: e.target.idItem.value,
							createdAt: new Date(e.target.createdAt.value),

              });
              if (successAction) {
                successAction();
              }
              alert('Se agrego exitosamente el dato');
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Lo sentimos no se logro agregar el dato');
              }
            }
          }}
        >
  				<div className="my-3">
					<TextField name="userId" label="Usuario" variant="outlined" fullWidth select>
						{
							dataUser.map(item => (
								<MenuItem key={item._id} value={item._id}>{item.loftyEmail}</MenuItem>
							))
						}
					</TextField>
				</div>
				<div className="my-3">
					<TextField name="idItem" label="Item" variant="outlined" fullWidth select>
						{
							dataLoftyitem.map(item => (
								<MenuItem key={item._id} value={item._id}>{item.description}</MenuItem>
							))
						}
					</TextField>
				</div>
				<div className="my-3">
					<TextField type="date" name="createdAt" label="Fecha de creacion" variant="outlined" fullWidth />
				</div>

          <div className="my-3 text-center">
            <Button type="submit" variant="outlined" color="primary">
              Guardar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(LoftylogcallAddModal);
