
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  createprofileImage,
} from '../api/profileImage.api';



const LoftyprofileImageAddModal = ({open, handleClose, successAction, token}) => {
  
  
  useEffect(() => {
    const handleDataLoftyApps = async () => {
      try {


      } catch (error) {
        // error
      }
    }
    handleDataLoftyApps();
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        Agregar profileImage
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async e => {
            e.preventDefault();
            try {
              await createprofileImage({
                token: token,
                							loftyFile: e.target.loftyFile.files[0],

              });
              if (successAction) {
                successAction();
              }
              alert('Se agrego exitosamente el dato');
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Lo sentimos no se logro agregar el dato');
              }
            }
          }}
        >
  				<div className="my-3">
					<label>Seleccione Archivo</label>
					<input className="form-control" type="file" name="loftyFile" />
				</div>

          <div className="my-3 text-center">
            <Button type="submit" variant="outlined" color="primary">
              Guardar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(LoftyprofileImageAddModal);
