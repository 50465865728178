
import axios from '../config/axios';

export const url = '/api/profileImage'

export const createprofileImage = ({
  token,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token ) {
		const formData = new FormData();
		formData.append('loftyFile', loftyFile);

    axios.post(`${url}/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',

        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updateprofileImage = ({
  token,
  _id,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && _id ) {
    axios.put(`${url}/update/${_id}`, {

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getAllprofileImage = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getOneprofileImage = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deleteprofileImage = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})

