
import axios from '../config/axios';

export const url = '/api/loglostitem'

export const createloglostitem = ({
  token,
	idItem,
	status,
	createdAt,

}) => new Promise((resolve, reject) => {
  if (token && idItem && status && createdAt ) {

    axios.post(`${url}/create`, {
		idItem,
		status,
		createdAt,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!idItem) {
		reject({
			status: 'error',
			info: 'idItem no esta definido',
		});
	}  else if (!status) {
		reject({
			status: 'error',
			info: 'status no esta definido',
		});
	}  else if (!createdAt) {
		reject({
			status: 'error',
			info: 'createdAt no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updateloglostitem = ({
  token,
  _id,
	idItem,
	status,
	createdAt,

}) => new Promise((resolve, reject) => {
  if (token && _id && idItem && status && createdAt ) {
    axios.put(`${url}/update/${_id}`, {
		idItem,
		status,
		createdAt,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!idItem) {
		reject({
			status: 'error',
			info: 'idItem no esta definido',
		});
	}  else if (!status) {
		reject({
			status: 'error',
			info: 'status no esta definido',
		});
	}  else if (!createdAt) {
		reject({
			status: 'error',
			info: 'createdAt no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getAllloglostitem = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getOneloglostitem = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deleteloglostitem = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})

