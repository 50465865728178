
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";


import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  createtransmision,
} from '../api/transmision.api';



const LoftytransmisionAddModal = ({open, handleClose, successAction, token}) => {
  
  
  useEffect(() => {
    const handleDataLoftyApps = async () => {
      try {


      } catch (error) {
        // error
      }
    }
    handleDataLoftyApps();
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        Agregar transmision
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async e => {
            e.preventDefault();
            try {
              await createtransmision({
                token: token,
                nameSpanish: e.target.nameSpanish.value,
							nameEnglish: e.target.nameEnglish.value,

              });
              if (successAction) {
                successAction();
              }
              alert('Se agrego exitosamente el dato');
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Lo sentimos no se logro agregar el dato');
              }
            }
          }}
        >
  				<div className="my-3">
					<TextField type="text" name="nameSpanish" label="Nombre en español" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="nameEnglish" label="Nombre en Ingles" variant="outlined" fullWidth />
				</div>

          <div className="my-3 text-center">
            <Button type="submit" variant="outlined" color="primary">
              Guardar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(LoftytransmisionAddModal);
