
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  createstate,
} from '../api/state.api';
import { getAllcountry } from '../api/country.api';



const LoftystateAddModal = ({open, handleClose, successAction, token}) => {
  const [dataLoftycountry, setDataLoftycountry] = useState([]);

  
  useEffect(() => {
    const handleDataLoftyApps = async () => {
      try {
				const resLoftycountry = await getAllcountry({ token });
				setDataLoftycountry(resLoftycountry.data);


      } catch (error) {
        // error
      }
    }
    handleDataLoftyApps();
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        Agregar state
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async e => {
            e.preventDefault();
            try {
              await createstate({
                token: token,
                name: e.target.name.value,
							idCountry: e.target.idCountry.value,

              });
              if (successAction) {
                successAction();
              }
              alert('Se agrego exitosamente el dato');
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Lo sentimos no se logro agregar el dato');
              }
            }
          }}
        >
  				<div className="my-3">
					<TextField type="text" name="name" label="Nombre" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField name="idCountry" label="Pais" variant="outlined" fullWidth select>
						{
							dataLoftycountry.map(item => (
								<MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
							))
						}
					</TextField>
				</div>

          <div className="my-3 text-center">
            <Button type="submit" variant="outlined" color="primary">
              Guardar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(LoftystateAddModal);
