
import React, {useState} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Redirect} from 'react-router-dom';
import { loginWithEmailAdmin } from '../api/user.admin.api';
import { addToken } from '../actions/auth.actions';

const Login = ({history, token, addTokenApp}) => {
  const [loading, setLoading] = useState(false);
  if (token) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="bg-light all-window container-flex-center">
      <Paper className="m-auto" style={{width: '300px'}}>
        <Container>
          <h2 className="text-center">
            Inicio de Sesión
          </h2>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setLoading(true);
              try {
                const res = await loginWithEmailAdmin({
                  email: e.target.email.value,
                  password: e.target.password.value,
                });
                addTokenApp({
                  token: res.token,
                  user: res.user,
                });
                history.push('/dashboard');
              } catch (error) {
                if (error.info) {
                  alert(error.info);
                } else {
                  alert('Lo sentimos no se pudo iniciar sesión por favor contacte a soporte de Lofty Apps');
                }
              }
              setLoading(false);
            }}
          >
            <div className="my-3">
              <TextField type="email" name="email" label="Correo" variant="outlined" fullWidth />
            </div>
            <div className="my-3">
              <TextField type="password" name="password" label="Contraseña" variant="outlined" fullWidth />
            </div>
            <div className="my-3">
              <Link to="/forgot">
                Olvidar Contraseña
              </Link>
            </div>
            <div className="text-center my-3">
              <Button disabled={loading} type="submit" variant="outlined" color="primary">
                {
                  loading ? 'Procesando': 'Iniciar'
                }
              </Button>
            </div>
          </form>
        </Container>
      </Paper>
    </div>
  );
}

const mapStateToProps = state => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addTokenApp: (doc) => dispatch(addToken(doc)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Login);

