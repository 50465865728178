
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert2';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DashboardLayout from '../layouts/DashboardLayout';
import Tabular from '../components/Tabular';
// modales dinamicos
import ItemAddModal from '../components/reportFoundItAddModal';
import ItemUpdateModal from '../components/reportFoundItUpdateModal';
import {
  getAllreportFoundIt,
  deletereportFoundIt,
} from '../api/reportFoundIt.api';

const ReportFoundItPage = ({ history, token, }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [doc, setDoc] = useState(undefined);
  const [data, setData] = useState([]);
  useEffect(() => {
    getAllreportFoundIt({ token }).then((res) => {
      setData(res.data);
    }).catch((error) => {
      console.log(error);
    });
  }, []);
  const handleSuccess = () => {
    getAllreportFoundIt({ token }).then((res) => {
      setData(res.data);
    }).catch((error) => {
      console.log(error);
    });
  }
  return (
    <DashboardLayout history={history}>
      {/* Modal para agregar item */}
      <ItemAddModal
        handleClose={() => setOpenAddModal(!openAddModal)}
        open={openAddModal}
        successAction={handleSuccess}
      />
      {/* Moal para actualizar un item */}
      <ItemUpdateModal
        handleClose={() => setOpenUpdateModal(!openUpdateModal)}
        doc={doc}
        open={openUpdateModal}
        successAction={handleSuccess}
      />
      <h2 className="text-center mb-4">
        reportFoundIt
      </h2>
      <div className="text-right mb-2">
        <Button onClick={() => setOpenAddModal(true)} variant="outlined" color="primary">
          Agregar
        </Button>
      </div>
      <div>
        <Tabular
          title="Item"
          columns={[
            {
							title: 'Usuario',
							field: 'userId'
						},
						{
							title: 'Objeto',
							field: 'idItem'
						},
						{
							title: 'Imagen',
							field: 'image'
						},
						{
							title: 'Fecha de creacion ',
							field: 'createdAt'
						},
						{
							title: 'Detalle',
							field: 'details'
						},
						{
							title: 'Direccion',
							field: 'address'
						},
						{
							title: 'Contacto',
							field: 'contact'
						},

            {
              title: 'Acciones',
              field: '_id',
              render: (rowData) => {
                const {_id} = rowData;
                return (
                  <div>
                    <IconButton onClick={() => history.push('/item/reportFoundIt/one/' + _id)}>
                      <VisibilityIcon/>
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setOpenUpdateModal(true);
                        setDoc(rowData);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={async () => {
                        try {
                          const resSwal = await Swal.fire({
                            icon: 'info',
                            title: 'Deseas eliminar este registro',
                            confirmButtonText: 'Si',
                            showCancelButton: true
                          });
                          if (resSwal.isConfirmed) {
                            await deletereportFoundIt({ token, _id });
                            alert('Se elimino el dato.');
                            handleSuccess();
                          }
                        } catch (error) {
                          if (error.info) {
                            Swal.fire({
                              position: 'top-end',
                              icon: 'error',
                              title: error.info,
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          } else {
                            Swal.fire({
                              position: 'top-end',
                              icon: 'error',
                              title: 'Error no se pudo eliminar',
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          }
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                );
              },
            }
          ]}
          data={data}
        />
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(ReportFoundItPage);

