
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DashboardLayout from '../layouts/DashboardLayout';

const ProfileUser = ({ history }) => (
  <DashboardLayout history={history}>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Container>
          <Paper>
            <Container className="py-2">
              <h5 className="text-center">
                Actualizar Perfil
              </h5>
              <form>
                <div className="my-3">
                  <TextField type="text" name="firstName" label="Nombre" variant="outlined" fullWidth />
                </div>
                <div className="my-3">
                  <TextField type="text" name="lastName" label="Apellido" variant="outlined" fullWidth />
                </div>
                <div className="my-3 text-center">
                  <Button variant="outlined" color="primary">
                    Guardar
                  </Button>
                </div>
              </form>
            </Container>
          </Paper>
        </Container>
      </Grid>
      <Grid item xs={12} md={6}>
        <Container>
          <Paper>
            <Container className="py-2">
              <h5 className="text-center">
                Cambiar Contraseña
              </h5>
              <form>
                <div className="my-3">
                  <TextField type="password" name="oldPassword" label="Contraseña" variant="outlined" fullWidth />
                </div>
                <div className="my-3">
                  <TextField type="password" name="newPassword" label="Nueva Contraseña" variant="outlined" fullWidth />
                </div>
                <div className="my-3">
                  <TextField type="password" name="confirmPassword" label="Confirmar Contraseña" variant="outlined" fullWidth />
                </div>
                <div className="my-3 text-center">
                  <Button variant="outlined" color="primary">
                    Guardar
                  </Button>
                </div>
              </form>
            </Container>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  </DashboardLayout>
);

export default ProfileUser;

