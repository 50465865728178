
import axios from '../config/axios';

export const url = '/api/accessories'

export const createaccessories = ({
  token,
	name,
	details,
	createdAt,
	idItem,
	userId,

}) => new Promise((resolve, reject) => {
  if (token && name && details && createdAt && idItem && userId ) {

    axios.post(`${url}/create`, {
		name,
		details,
		createdAt,
		idItem,
		userId,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!details) {
		reject({
			status: 'error',
			info: 'details no esta definido',
		});
	}  else if (!createdAt) {
		reject({
			status: 'error',
			info: 'createdAt no esta definido',
		});
	}  else if (!idItem) {
		reject({
			status: 'error',
			info: 'idItem no esta definido',
		});
	}  else if (!userId) {
		reject({
			status: 'error',
			info: 'userId no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updateaccessories = ({
  token,
  _id,
	name,
	details,
	createdAt,
	idItem,
	userId,

}) => new Promise((resolve, reject) => {
  if (token && _id && name && details && createdAt && idItem && userId ) {
    axios.put(`${url}/update/${_id}`, {
		name,
		details,
		createdAt,
		idItem,
		userId,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!name) {
		reject({
			status: 'error',
			info: 'name no esta definido',
		});
	}  else if (!details) {
		reject({
			status: 'error',
			info: 'details no esta definido',
		});
	}  else if (!createdAt) {
		reject({
			status: 'error',
			info: 'createdAt no esta definido',
		});
	}  else if (!idItem) {
		reject({
			status: 'error',
			info: 'idItem no esta definido',
		});
	}  else if (!userId) {
		reject({
			status: 'error',
			info: 'userId no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getAllaccessories = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getOneaccessories = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deleteaccessories = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})

