
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Navbar from '../components/NavbarDashboard';

const DashboardLayout = ({children, history, token, user}) => {
  if (!token) {
    return <Redirect to="/" />;
  }
  return (
    <Grid className="bg-light" container>
      <Grid item md={3} lg={2} xl={2}>
        <Paper elevation={4} className="hmax-100vh">
          <Container>
            <div className="text-center my-2">
              /* <img src="/logo512.png" style={{width: '80%'}} /> */
              <Avatar className="m-auto">
                user?.firstName
              </Avatar>
              <p>
                {`${user?.firstName} ${user?.lastName}`}
              </p>
            </div>
            <List>
              <ListItem onClick={() => history.push('/dashboard')} button>
                Dashboard
              </ListItem>
              <ListItem onClick={() => history.push('/loftyusers')} button>
                Usuarios
              </ListItem>
							<ListItem onClick={() => history.push('/item/item')} button>
								Objeto
							</ListItem>
							<ListItem onClick={() => history.push('/item/country')} button>
								Pais
							</ListItem>
							<ListItem onClick={() => history.push('/item/transmision')} button>
								Transmision
							</ListItem>
							<ListItem onClick={() => history.push('/item/reportFoundIt')} button>
								Reportes
							</ListItem>
							<ListItem onClick={() => history.push('/item/VeterinaryClinic')} button>
								Veterinaria
							</ListItem>
							<ListItem onClick={() => history.push('/item/image')} button>
								Imagen
							</ListItem>
							<ListItem onClick={() => history.push('/item/profileImage')} button>
								Imagen de Perfil
							</ListItem>
							<ListItem onClick={() => history.push('/item/state')} button>
								Estado/Departamento
							</ListItem>
							<ListItem onClick={() => history.push('/item/accessories')} button>
								Accesorios
							</ListItem>
							<ListItem onClick={() => history.push('/item/logcall')} button>
								Log de Llamadas
							</ListItem>
							<ListItem onClick={() => history.push('/item/loglostitem')} button>
								Log de objetos perdidos
							</ListItem>

            </List>
          </Container>
        </Paper>
      </Grid>
      <Grid item md={9} lg={10} xl={10}>
        <Navbar history={history} />
        <Container className="py-3">
          {children}
        </Container>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(DashboardLayout);

