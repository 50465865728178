
import axios from '../config/axios';

export const url = '/api/transmision'

export const createtransmision = ({
  token,
	nameSpanish,
	nameEnglish,

}) => new Promise((resolve, reject) => {
  if (token && nameSpanish && nameEnglish ) {

    axios.post(`${url}/create`, {
		nameSpanish,
		nameEnglish,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!nameSpanish) {
		reject({
			status: 'error',
			info: 'nameSpanish no esta definido',
		});
	}  else if (!nameEnglish) {
		reject({
			status: 'error',
			info: 'nameEnglish no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updatetransmision = ({
  token,
  _id,
	nameSpanish,
	nameEnglish,

}) => new Promise((resolve, reject) => {
  if (token && _id && nameSpanish && nameEnglish ) {
    axios.put(`${url}/update/${_id}`, {
		nameSpanish,
		nameEnglish,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!nameSpanish) {
		reject({
			status: 'error',
			info: 'nameSpanish no esta definido',
		});
	}  else if (!nameEnglish) {
		reject({
			status: 'error',
			info: 'nameEnglish no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getAlltransmision = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getOnetransmision = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deletetransmision = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})

