
import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import { getLoggedUserAdmin } from './api/user.admin.api';
import { deletetoken, addUser } from './actions/auth.actions';

// css
import './App.css';

// pages
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Error404 from './pages/Error404';
import InitialDashboard from './pages/InitialDashboard';
import ProfileUser from './pages/ProfileUser';
import LoftyUserPage from './pages/LoftyUserPage';
// TODO entidades dinamicas
import itemPage from './pages/itemPage';
import itemOnePage from './pages/itemOnePage';
import countryPage from './pages/countryPage';
import countryOnePage from './pages/countryOnePage';
import transmisionPage from './pages/transmisionPage';
import transmisionOnePage from './pages/transmisionOnePage';
import reportFoundItPage from './pages/reportFoundItPage';
import reportFoundItOnePage from './pages/reportFoundItOnePage';
import VeterinaryClinicPage from './pages/VeterinaryClinicPage';
import VeterinaryClinicOnePage from './pages/VeterinaryClinicOnePage';
import imagePage from './pages/imagePage';
import imageOnePage from './pages/imageOnePage';
import profileImagePage from './pages/profileImagePage';
import profileImageOnePage from './pages/profileImageOnePage';
import statePage from './pages/statePage';
import stateOnePage from './pages/stateOnePage';
import accessoriesPage from './pages/accessoriesPage';
import accessoriesOnePage from './pages/accessoriesOnePage';
import logcallPage from './pages/logcallPage';
import logcallOnePage from './pages/logcallOnePage';
import loglostitemPage from './pages/loglostitemPage';
import loglostitemOnePage from './pages/loglostitemOnePage';


const App = ({token, addUserApp, deletetokenApp}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getLoggedUserAdmin({token}).then((res) => {
      addUserApp(res.user);
      setLoading(false);
    }).catch(() => {
      deletetokenApp();
      setLoading(false);
    });
  }, []);
  if (loading) {
    return (
      <div>
        <h2 className="text-center">
          Cargando ...
        </h2>
      </div>
    );
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/reset-password/:id" component={ResetPassword} />
        <Route exact path="/dashboard" component={InitialDashboard} />
        <Route exact path="/profile" component={ProfileUser} />
        <Route exact path="/loftyusers" component={LoftyUserPage} />
				<Route exact path="/item/item" component={itemPage} />
				<Route exact path="/item/item/one/:_id" component={itemOnePage} />
				<Route exact path="/item/country" component={countryPage} />
				<Route exact path="/item/country/one/:_id" component={countryOnePage} />
				<Route exact path="/item/transmision" component={transmisionPage} />
				<Route exact path="/item/transmision/one/:_id" component={transmisionOnePage} />
				<Route exact path="/item/reportFoundIt" component={reportFoundItPage} />
				<Route exact path="/item/reportFoundIt/one/:_id" component={reportFoundItOnePage} />
				<Route exact path="/item/VeterinaryClinic" component={VeterinaryClinicPage} />
				<Route exact path="/item/VeterinaryClinic/one/:_id" component={VeterinaryClinicOnePage} />
				<Route exact path="/item/image" component={imagePage} />
				<Route exact path="/item/image/one/:_id" component={imageOnePage} />
				<Route exact path="/item/profileImage" component={profileImagePage} />
				<Route exact path="/item/profileImage/one/:_id" component={profileImageOnePage} />
				<Route exact path="/item/state" component={statePage} />
				<Route exact path="/item/state/one/:_id" component={stateOnePage} />
				<Route exact path="/item/accessories" component={accessoriesPage} />
				<Route exact path="/item/accessories/one/:_id" component={accessoriesOnePage} />
				<Route exact path="/item/logcall" component={logcallPage} />
				<Route exact path="/item/logcall/one/:_id" component={logcallOnePage} />
				<Route exact path="/item/loglostitem" component={loglostitemPage} />
				<Route exact path="/item/loglostitem/one/:_id" component={loglostitemOnePage} />

        <Route exact path="*" component={Error404} />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addUserApp: (user) => dispatch(addUser(user)),
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

