
import axios from 'axios';

// const url = 'http://localhost:8080';

// const url = 'https://loftyapps.herokuapp.com';

/* const instancePro = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
  },
}); */

export default axios;

