
import React from 'react';

const Error404 = () => (
  <div>
    Error 404
  </div>
);

export default Error404;
