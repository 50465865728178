
import axios from '../config/axios';

export const url = '/api/useradmin';

export const createUserAdmin = ({
  token,
  email,
  password,
  firstName,
  lastName,
}) => new Promise((resolve, reject) => {
  if (email && password && firstName && lastName && token) {
    axios.post(`${url}/create/user/email`, {
      email,
      password,
      firstName,
      lastName,
    }, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((err) => reject(err));
  } else if (!email) {
    reject({
      status: 'error',
      info: 'Correo no esta definido',
    });
  } else if (!password) {
    reject({
      status: 'error',
      ihfo: 'Contraseña no definido',
    });
  } else if (!firstName) {
    reject({
      status: 'error',
      info: 'Nombre no definido',
    });
  } else if (!lastName) {
    reject({
      status: 'error',
      info: 'Apellido no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const loginWithEmailAdmin = ({ email, password }) => new Promise((resolve, reject) => {
  if (email && password) {
    axios.post(`${url}/login/email`, {
      email, password
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((err) => reject(err));
  } else if (!email) {
    reject({
      status: 'error',
      info: 'Correo no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Contraseña no definido',
    });
  }
});

export const changePasswordAdmin = ({
  newPassword,
  confirmNewPasssword,
  token,
}) => new Promise((resolve, reject) => {
  if (newPassword && confirmNewPasssword && newPassword === confirmNewPasssword && token) {
    axios.put(`${url}/change/password'`, {
      newPassword,
      confirmNewPasssword,
    }, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((err) => reject(err));
  } else if (!newPassword) {
    reject({
      status: 'error',
      info: 'Contraseña es requerida'
    });
  } else if (!confirmNewPasssword) {
    reject({
      status: 'error',
      info: 'Confirmar contraseña es requerida'
    });
  } else if (newPassword !== confirmNewPasssword) {
    reject({
      status: 'error',
      info: 'Las contraseñas no coinciden'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const updateInfoAdmin = ({
  firstName,
  lastName,
  token,
}) => new Promise((resolve, reject) => {
  if (firstName && lastName && token) {
    axios.put(`${url}/update/info`, {
      firstName,
      lastName,
    }, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((err) => reject(err));
  } else if (!firstName) {
    reject({
      status: 'error',
      info: 'Nombre es requerido',
    });
  } else if (!lastName) {
    reject({
      status: 'error',
      info: 'Apellido es requerido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getLoggedUserAdmin = ({ token }) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/one`, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((err) => reject(err));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    })
  }
});

export const logoutAdmin = ({ token }) => new Promise((resolve, reject) => {
  if (token) {
    axios.post(`${url}/logout`, {}, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((err) => reject(err));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

