
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert2';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DashboardLayout from '../layouts/DashboardLayout';
import Tabular from '../components/Tabular';
import Paper from '@material-ui/core/Paper';
import {
  getOnecountry,
} from '../api/country.api';

const CountryOnePage = ({ history, token, match}) => {
  const { params } = match;
  const { _id } = params;
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [doc, setDoc] = useState(undefined);
  const [data, setData] = useState({});
  useEffect(() => {
    getOnecountry({ token, _id }).then((res) => {
      setData(res.data);
    }).catch((error) => {
      console.log(error);
    });
  }, []);
  return (
    <DashboardLayout history={history}>
      <h2 className="text-center mb-4">
        Pais
      </h2>
      <Paper className="container">
        <div>
					<b>Nombre:</b> {' '} {data?.name}
				</div>
				<div>
					<b>Codigo de telefono:</b> {' '} {data?.code}
				</div>

      </Paper>
    </DashboardLayout>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(CountryOnePage);

