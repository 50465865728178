
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
  createitem,
} from '../api/item.api';
import { getAlltransmision } from '../api/transmision.api';
import { getAllcountry } from '../api/country.api';
import { getAllVeterinaryClinic } from '../api/VeterinaryClinic.api';
import { getAllstate } from '../api/state.api';

import { getUsers } from '../api/user.api';

const LoftyitemAddModal = ({open, handleClose, successAction, token}) => {
  const [dataLoftytransmision, setDataLoftytransmision] = useState([]);
const [dataLoftycountry, setDataLoftycountry] = useState([]);
const [dataLoftyVeterinaryClinic, setDataLoftyVeterinaryClinic] = useState([]);
const [dataLoftystate, setDataLoftystate] = useState([]);

  const [dataUser, setDataUser] = useState([]);

  useEffect(() => {
    const handleDataLoftyApps = async () => {
      try {
				const resLoftytransmision = await getAlltransmision({ token });
				setDataLoftytransmision(resLoftytransmision.data);
				const resLoftycountry = await getAllcountry({ token });
				setDataLoftycountry(resLoftycountry.data);
				const resLoftyVeterinaryClinic = await getAllVeterinaryClinic({ token });
				setDataLoftyVeterinaryClinic(resLoftyVeterinaryClinic.data);
				const resLoftystate = await getAllstate({ token });
				setDataLoftystate(resLoftystate.data);

				const resUser = await getUsers({ token });
				setDataUser(resUser.data);

      } catch (error) {
        // error
      }
    }
    handleDataLoftyApps();
  }, []);
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>
        Agregar item
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={async e => {
            e.preventDefault();
            try {
              await createitem({
                token: token,
                description: e.target.description.value,
							type: e.target.type.value,
							idTransmision: e.target.idTransmision.value,
							idCountry: e.target.idCountry.value,
							city: e.target.city.value,
							idUser: e.target.idUser.value,
							gender: e.target.gender.value,
							lost: e.target.lost.checked,
							color: e.target.color.value,
							numberMicroship: e.target.numberMicroship.value,
							colorcast: e.target.colorcast.value,
							species: e.target.species.value,
							idVeterinaryClinic: e.target.idVeterinaryClinic.value,
							extraInformation: e.target.extraInformation.value,
							medicines: e.target.medicines.value,
							allergies: e.target.allergies.value,
							phone1: e.target.phone1.value,
							phone2: e.target.phone2.value,
							idState: e.target.idState.value,
							vaccines: e.target.vaccines.value,
							surgeries: e.target.surgeries.value,
							childbirth: e.target.childbirth.value,
							pedigree: e.target.pedigree.value,
							dewormers: e.target.dewormers.value,
							fractures: e.target.fractures.value,
							scars: e.target.scars.value,
							reward: e.target.reward.value,
							brand: e.target.brand.value,
							imei: e.target.imei.value,
							age: new Date(e.target.age.value),
							validItem: e.target.validItem.checked,
							numberPlate: e.target.numberPlate.value,
							haveReward: e.target.haveReward.checked,
							textModel: e.target.textModel.value,
							idPdf: e.target.idPdf.value,

              });
              if (successAction) {
                successAction();
              }
              alert('Se agrego exitosamente el dato');
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Lo sentimos no se logro agregar el dato');
              }
            }
          }}
        >
  				<div className="my-3">
					<TextField type="text" name="description" label="Descripcion" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="type" label="Tipo" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField name="idTransmision" label="Transmision" variant="outlined" fullWidth select>
						{
							dataLoftytransmision.map(item => (
								<MenuItem key={item._id} value={item._id}>{item.nameSpanish}</MenuItem>
							))
						}
					</TextField>
				</div>
				<div className="my-3">
					<TextField name="idCountry" label="Pais" variant="outlined" fullWidth select>
						{
							dataLoftycountry.map(item => (
								<MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
							))
						}
					</TextField>
				</div>
				<div className="my-3">
					<TextField type="text" name="city" label="Ciudad" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField name="idUser" label="Usuario" variant="outlined" fullWidth select>
						{
							dataUser.map(item => (
								<MenuItem key={item._id} value={item._id}>{item.loftyEmail}</MenuItem>
							))
						}
					</TextField>
				</div>
				<div className="my-3">
					<TextField type="text" name="gender" label="Genero" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<FormControl className="my-4">
						<FormControlLabel
							control={(
								<Checkbox
									name="lost"
									color="primary"
								/>
							)}
							label="Perdido"
						/>
					</FormControl>
				</div>
				<div className="my-3">
					<TextField type="text" name="color" label="Color" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="numberMicroship" label="Numero de microship" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="colorcast" label="Raza" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="species" label="Especie" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField name="idVeterinaryClinic" label="Veterinaria" variant="outlined" fullWidth select>
						{
							dataLoftyVeterinaryClinic.map(item => (
								<MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
							))
						}
					</TextField>
				</div>
				<div className="my-3">
					<TextField type="text" name="extraInformation" label="Información extra" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="medicines" label="Medicamentos" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="allergies" label="Alergias" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="phone1" label="Telefono1" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="phone2" label="Telefono 2" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField name="idState" label="Estado / Departamento" variant="outlined" fullWidth select>
						{
							dataLoftystate.map(item => (
								<MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
							))
						}
					</TextField>
				</div>
				<div className="my-3">
					<TextField type="text" name="vaccines" label="Vacunas" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="surgeries" label="Cirugías" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="number" name="childbirth" label="Número de partos" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="pedigree" label="Pedigree" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="dewormers" label="Desparasitantes" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="fractures" label="Fracturas" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="scars" label="Cicatrices" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="number" name="reward" label="Recompensa" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="brand" label="Marca" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="imei" label="IMEI #" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="date" name="age" label="Fecha de nacimiento" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<FormControl className="my-4">
						<FormControlLabel
							control={(
								<Checkbox
									name="validItem"
									color="primary"
								/>
							)}
							label="Valido"
						/>
					</FormControl>
				</div>
				<div className="my-3">
					<TextField type="text" name="numberPlate" label="Numero de Placa" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<FormControl className="my-4">
						<FormControlLabel
							control={(
								<Checkbox
									name="haveReward"
									color="primary"
								/>
							)}
							label="Posee Recompensa"
						/>
					</FormControl>
				</div>
				<div className="my-3">
					<TextField type="text" name="textModel" label="Modelo" variant="outlined" fullWidth />
				</div>
				<div className="my-3">
					<TextField type="text" name="idPdf" label="Archivo PDF" variant="outlined" fullWidth />
				</div>

          <div className="my-3 text-center">
            <Button type="submit" variant="outlined" color="primary">
              Guardar
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(LoftyitemAddModal);
