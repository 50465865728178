
import axios from '../config/axios';

export const url = '/api/logcall'

export const createlogcall = ({
  token,
	userId,
	idItem,
	createdAt,

}) => new Promise((resolve, reject) => {
  if (token && userId && idItem && createdAt ) {

    axios.post(`${url}/create`, {
		userId,
		idItem,
		createdAt,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!userId) {
		reject({
			status: 'error',
			info: 'userId no esta definido',
		});
	}  else if (!idItem) {
		reject({
			status: 'error',
			info: 'idItem no esta definido',
		});
	}  else if (!createdAt) {
		reject({
			status: 'error',
			info: 'createdAt no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updatelogcall = ({
  token,
  _id,
	userId,
	idItem,
	createdAt,

}) => new Promise((resolve, reject) => {
  if (token && _id && userId && idItem && createdAt ) {
    axios.put(`${url}/update/${_id}`, {
		userId,
		idItem,
		createdAt,

    }, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!userId) {
		reject({
			status: 'error',
			info: 'userId no esta definido',
		});
	}  else if (!idItem) {
		reject({
			status: 'error',
			info: 'idItem no esta definido',
		});
	}  else if (!createdAt) {
		reject({
			status: 'error',
			info: 'createdAt no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const getAlllogcall = ({
  token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const getOnelogcall = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}/one/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const deletelogcall = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})

