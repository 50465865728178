
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert2';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DashboardLayout from '../layouts/DashboardLayout';
import Tabular from '../components/Tabular';
import Paper from '@material-ui/core/Paper';
import {
  getOneitem,
} from '../api/item.api';

const ItemOnePage = ({ history, token, match}) => {
  const { params } = match;
  const { _id } = params;
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [doc, setDoc] = useState(undefined);
  const [data, setData] = useState({});
  useEffect(() => {
    getOneitem({ token, _id }).then((res) => {
      setData(res.data);
    }).catch((error) => {
      console.log(error);
    });
  }, []);
  return (
    <DashboardLayout history={history}>
      <h2 className="text-center mb-4">
        Objeto
      </h2>
      <Paper className="container">
        <div>
					<b>Descripcion:</b> {' '} {data?.description}
				</div>
				<div>
					<b>Tipo:</b> {' '} {data?.type}
				</div>
				<div>
					<b>Transmision:</b> {' '} {data?.idTransmision}
				</div>
				<div>
					<b>Pais:</b> {' '} {data?.idCountry}
				</div>
				<div>
					<b>Ciudad:</b> {' '} {data?.city}
				</div>
				<div>
					<b>Usuario:</b> {' '} {data?.idUser}
				</div>
				<div>
					<b>Genero:</b> {' '} {data?.gender}
				</div>
				<div>
					<b>Perdido:</b> {' '} {data?.lost}
				</div>
				<div>
					<b>Color:</b> {' '} {data?.color}
				</div>
				<div>
					<b>Numero de microship:</b> {' '} {data?.numberMicroship}
				</div>
				<div>
					<b>Raza:</b> {' '} {data?.colorcast}
				</div>
				<div>
					<b>Especie:</b> {' '} {data?.species}
				</div>
				<div>
					<b>Veterinaria:</b> {' '} {data?.idVeterinaryClinic}
				</div>
				<div>
					<b>Información extra:</b> {' '} {data?.extraInformation}
				</div>
				<div>
					<b>Medicamentos:</b> {' '} {data?.medicines}
				</div>
				<div>
					<b>Alergias:</b> {' '} {data?.allergies}
				</div>
				<div>
					<b>Telefono1:</b> {' '} {data?.phone1}
				</div>
				<div>
					<b>Telefono 2:</b> {' '} {data?.phone2}
				</div>
				<div>
					<b>Estado / Departamento:</b> {' '} {data?.idState}
				</div>
				<div>
					<b>Vacunas:</b> {' '} {data?.vaccines}
				</div>
				<div>
					<b>Cirugías:</b> {' '} {data?.surgeries}
				</div>
				<div>
					<b>Número de partos:</b> {' '} {data?.childbirth}
				</div>
				<div>
					<b>Pedigree:</b> {' '} {data?.pedigree}
				</div>
				<div>
					<b>Desparasitantes:</b> {' '} {data?.dewormers}
				</div>
				<div>
					<b>Fracturas:</b> {' '} {data?.fractures}
				</div>
				<div>
					<b>Cicatrices:</b> {' '} {data?.scars}
				</div>
				<div>
					<b>Recompensa:</b> {' '} {data?.reward}
				</div>
				<div>
					<b>Marca:</b> {' '} {data?.brand}
				</div>
				<div>
					<b>IMEI #:</b> {' '} {data?.imei}
				</div>
				<div>
					<b>Fecha de nacimiento:</b> {' '} {data?.age}
				</div>
				<div>
					<b>Valido:</b> {' '} {data?.validItem}
				</div>
				<div>
					<b>Numero de Placa:</b> {' '} {data?.numberPlate}
				</div>
				<div>
					<b>Posee Recompensa:</b> {' '} {data?.haveReward}
				</div>
				<div>
					<b>Modelo:</b> {' '} {data?.textModel}
				</div>
				<div>
					<b>Archivo PDF:</b> {' '} {data?.idPdf}
				</div>

      </Paper>
    </DashboardLayout>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(ItemOnePage);

