
import React from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const ResetPassword = () => (
  <div className="bg-light all-window container-flex-center">
    <Paper className="m-auto" style={{width: '300px'}}>
      <Container>
        <h2 className="text-center">
          Resetear Contraseña
        </h2>
        <form>
          <div className="my-3">
          <TextField type="password" name="password" label="Contraseña" variant="outlined" fullWidth />
          </div>
          <div className="my-3">
            <TextField type="password" name="confirmPassword" label="Confirmar Contraseña" variant="outlined" fullWidth />
          </div>
          <div className="text-center my-3">
            <Button variant="outlined" color="primary">
              Iniciar
            </Button>
          </div>
        </form>
      </Container>
    </Paper>
  </div>
);

export default ResetPassword;

