
import React from 'react';
import Layout from '../layouts/DashboardLayout';
import InfoIcon from '@material-ui/icons/Info';

const InitialDashboard = ({history}) => (
  <Layout history={history}>
    <h2 className="text-center">
      <InfoIcon style={{fontSize: '3rem'}} />
    </h2>
    <h3 className="text-center">
      Muy Pronto
    </h3>
    <p className="text-center">
      Estamos trabajando para que tu dashboard administrativo tenga
      gráficos y estadisticas de tu aplicación web.
    </p>
  </Layout>
);

export default InitialDashboard;

