
import React from 'react';
import MaterialTable from 'material-table';

function Tabular({
  data, columns, title, ready,
}) {
  return (
    <MaterialTable
      title={title}
      columns={columns}
      data={data}
      isLoading={ready}
      localization={{
        toolbar: {
          searchPlaceholder: 'Buscar',
          searchTooltip: 'Buscar',
        },
        pagination: {
          labelRowsSelect: 'filas',
          labelRowsPerPage: 'hola',
          previousTooltip: 'Página Anterior',
          nextTooltip: 'Siguiente Página',
          lastTooltip: 'Ultima Página',
          firstTooltip: 'Primera Página',
          labelDisplayedRows: '{from}-{to} de {count}',
        },
        body: {
          emptyDataSourceMessage: 'No hay datos',
        },
      }}
    />
  );
}

export default Tabular;

