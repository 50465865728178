
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert2';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DashboardLayout from '../layouts/DashboardLayout';
import Tabular from '../components/Tabular';
import {
  getUsers,
} from '../api/user.api';

const LoftyUserPage = ({ history, token, }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getUsers({ token }).then((res) => {
      setData(res.data);
    }).catch((error) => {
      console.log(error);
    });
  }, []);
  return (
    <DashboardLayout history={history}>
      <h2 className="text-center mb-4">
        Usuarios
      </h2>
      <div>
        <Tabular
          title="Item"
          columns={[
            {
							title: 'Correo',
							field: 'loftyEmail',
						},
						{
							title: 'Tipo de registro',
							field: 'loftyTypeRegister ',
						},
						{
							title: 'Nombre Completo',
							field: 'loftyName ',
						},
						{
							title: 'Usuario',
							field: 'loftyUsername ',
						},
						{
							title: 'idProfileImage',
							field: 'idProfileImage'
						},

            {
              title: 'Acciones',
              field: '_id',
              render: (rowData) => {
                const {_id} = rowData;
                return (
                  <div>
                    <IconButton onClick={() => history.push('/item/one')}>
                      <VisibilityIcon/>
                    </IconButton>
                  </div>
                );
              },
            }
          ]}
          data={data}
        />
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = state => state.auth;

export default connect(mapStateToProps)(LoftyUserPage);

